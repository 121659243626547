<template>
    <div class="SystemSettings full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex ">
                        <v-col cols="6" class="pa-0 pr-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('general.date') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-dialog ref="datedialog" v-model="datemodal" :return-value.sync="datePicker" persistent width="350px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="systemsettings.date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details="auto"
                                                outlined
                                                class="is-modal"
                                                :class="{'noAction': !checkUserRights('systemSettingsEdit')}"
                                                id="datemodal"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="datePicker"
                                            scrollable
                                            :show-current="false"
                                            header-color="disiDarkblue"
                                            color="disiIrisblue"
                                            :locale=langSelection
                                            first-day-of-week="1"
                                            width="350"
                                            id="datemodal-datepicker"
                                        >
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2" @click="datemodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2" @click="$refs.datedialog.save(datePicker)">{{ $t('footerButton.save') }}</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.dateFormat') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectDateFormat" item-text="name" item-value="value" v-model="systemsettings.dateFormat" class="systemsettings-dateFormat-dropdown" outlined hide-details="auto" attach  :readonly="!checkUserRights('systemSettingsEdit')" :class="{'noAction': !checkUserRights('systemSettingsEdit')}" />
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('general.time') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-dialog ref="timedialog" v-model="timemodal" :return-value.sync="systemsettings.time" persistent width="350px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="systemsettings.time"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details="auto"
                                                outlined
                                                class="is-modal"
                                                :class="{'noAction': !checkUserRights('systemSettingsEdit')}"
                                                id="time-modal"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="systemsettings.time"
                                            scrollable
                                            header-color="disiDarkblue"
                                            width="350"
                                            :format="timeformatSelection"
                                            id="timemodal-timepicker"
                                        >
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2" @click="timemodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2" @click="$refs.timedialog.save(systemsettings.time)">{{ $t('footerButton.save') }}</v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.timeFormat') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectTimeFormat" v-model="systemsettings.timeFormat" class="systemsettings-timeFormat-dropdown" outlined hide-details="auto" attach :readonly="!checkUserRights('systemSettingsEdit')" :class="{'noAction': !checkUserRights('systemSettingsEdit')}" />
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.timeZone') }}</p>
                                </v-col>
                                <v-col class="col-8 timeZones">
                                    <v-select :items="selectTimeZone" item-text="name" item-value="value" v-model="systemsettings.timeZone" class="systemsettings-timeZone-dropdown" outlined hide-details="auto" attach :readonly="!checkUserRights('systemSettingsEdit')" :class="{'noAction': !checkUserRights('systemSettingsEdit')}" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.language') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectLanguage" item-text="name" item-value="value" v-model="systemsettings.language" class="systemsettings-language-dropdown" outlined hide-details="auto" attach  :readonly="!checkUserRights('systemSettingsEdit')" :class="{'noAction': !checkUserRights('systemSettingsEdit')}" />
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.companyLogo') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-dialog ref="logodialog" v-model="logomodal" :return-value.sync="systemsettings.companyLogo" persistent width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="showLogo"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details="auto"
                                                outlined
                                                class="is-modal"
                                                :class="{'noAction': !checkUserRights('systemSettingsEdit')}"
                                                id="logomodal"
                                            >
                                                <template v-slot:append>
                                                    <div class="deleteContainer" @click="deleteLogo">
                                                        <img v-if="systemsettings.companyLogo.length > 0 && checkUserRights('systemSettingsEdit')" width="17" height="17" src="../assets/cancel_disiBluegreyDark.svg" >
                                                    </div>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <div>
                                            <div class="dialogcontainer pa-8">
                                                <p>{{ $t('system.selectCompanyLogo') }}</p>
                                                <v-list>
                                                    <v-list-item-group
                                                        v-model="systemsettings.companyLogo"
                                                        color="disiIrisblue"
                                                        class="list mt-4 mb-8"
                                                        id="logomodal-list"
                                                        mandatory
                                                    >
                                                        <v-list-item
                                                            v-for="(item, i) in this.filelist"
                                                            :key="i"
                                                        >
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="item"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                                <div class="button-container d-flex justify-space-between">
                                                    <v-btn elevation="0" class="content-btn small" @click="logomodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                                    <v-btn elevation="0" class="content-btn small" @click="$refs.logodialog.save(systemsettings.companyLogo)">{{ $t('footerButton.save') }}</v-btn>
                                                </div>
                                            </div>

                                        </div>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.companyName') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input inputName="systemsettings-companyName" @onInputFocus="onInputFocus" :readonly="!checkUserRights('systemSettingsEdit')" :class="{'noAction': !checkUserRights('systemSettingsEdit')}" :rules="[rules.alphanumericSpecCharName, rules.maxLengthName]"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('systemSettingsEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'SystemSettings',
    components: {
        TextInput,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            originalSystemsettings: {},
            datePicker: '',
            selectLanguage: [
                {
                    'name': 'English',
                    'value': 'English'
                },{
                    'name': 'Deutsch',
                    'value': 'Deutsch'
                }
            ],
            selectDateFormat: [
                {
                    'name': 'YYYY-MM-DD',
                    'value': 'YYYY-MM-DD hh:mm:ss'
                },{
                    'name': 'DD.MM.YYYY',
                    'value': 'DD.MM.YYYY hh:mm:ss'
                },{
                    'name': 'MM/DD/YYYY',
                    'value': 'MM/DD/YYYY hh:mm:ss'
                }
            ],
            selectTimeFormat: [
                '24h',
                'AM/PM'
            ],
            selectTimeZone: [
                {
                    'name': 'UTC-12:00 – Baker Island Time',
                    'value': 'UTC-12:00'
                },{
                    'name': 'UTC-11:00 – Samoa Standard Time',
                    'value': 'UTC-11:00'
                },{
                    'name': 'UTC-10:00 – Hawaii-Aleutian Standard Time',
                    'value': 'UTC-10:00'
                },{
                    'name': 'UTC-09:00 – Alaska Standard Time',
                    'value': 'UTC-09:00'
                },{
                    'name': 'UTC-08:00 – Pacific Standard Time',
                    'value': 'UTC-08:00'
                },{
                    'name': 'UTC-07:00 – Mountain Standard Time',
                    'value': 'UTC-07:00'
                },{
                    'name': 'UTC-06:00 – Central Standard Time',
                    'value': 'UTC-06:00'
                },{
                    'name': 'UTC-05:00 – Eastern Standard Time',
                    'value': 'UTC-05:00'
                },{
                    'name': 'UTC-04:30 – Venezuelan Standard Time',
                    'value': 'UTC-04:30'
                },{
                    'name': 'UTC-04:00 – Atlantic Standard Time',
                    'value': 'UTC-04:00'
                },{
                    'name': 'UTC-03:00 – Amazon Standard Time',
                    'value': 'UTC-03:00'
                },{
                    'name': 'UTC-02:00 – Fernando de Noronha Time, South Georgia Time',
                    'value': 'UTC-02:00'
                },{
                    'name': 'UTC-01:00 – Azores Standard Time, Cape Verde Time',
                    'value': 'UTC-01:00'
                },{
                    'name': 'UTC-00:00 – Western European Time, Greenwich Mean Time',
                    'value': 'UTC-00:00'
                },{
                    'name': 'UTC+01:00 – Central European Time, West African Time',
                    'value': 'UTC+01:00'
                },{
                    'name': 'UTC+02:00 – Eastern European Time, Central African Time',
                    'value': 'UTC+02:00'
                },{
                    'name': 'UTC+03:00 – Moscow Standard Time, Eastern African Time',
                    'value': 'UTC+03:00'
                },{
                    'name': 'UTC+03:30 – Iran Standard Time',
                    'value': 'UTC+03:30'
                },{
                    'name': 'UTC+04:00 – Gulf Standard Time, Samara Standard Time',
                    'value': 'UTC+04:00'
                },{
                    'name': 'UTC+04:30 – Afghanistan Time',
                    'value': 'UTC+04:30'
                },{
                    'name': 'UTC+05:00 – Pakistan Standard Time, Yekaterinburg Standard Time',
                    'value': 'UTC+05:00'
                },{
                    'name': 'UTC+05:30 – Indian Standard Time, Sri Lanka Time',
                    'value': 'UTC+05:30'
                },{
                    'name': 'UTC+06:00 – Bangladesh Time, Bhutan Time, Novosibirsk Standard Time',
                    'value': 'UTC+06:00'
                },{
                    'name': 'UTC+07:00 – Indochina Time, Krasnoyarsk Standard Time',
                    'value': 'UTC+07:00'
                },{
                    'name': 'UTC+08:00 – Chinese Standard Time, Australian Western Standard Time',
                    'value': 'UTC+08:00'
                },{
                    'name': 'UTC+09:00 – Japan Standard Time, Korea Standard Time, Chita Standard Time',
                    'value': 'UTC+09:00'
                },{
                    'name': 'UTC+09:30 – Australian Central Standard Time',
                    'value': 'UTC+09:30'
                },{
                    'name': 'UTC+10:00 – Australian Eastern Standard Time, Vladivostok Standard Time',
                    'value': 'UTC+10:00'
                },{
                    'name': 'UTC+11:00 – Solomon Island Time, Magadan Standard Time',
                    'value': 'UTC+11:00'
                },{
                    'name': 'UTC+12:00 – New Zealand Time, Fiji Time, Kamchatka Standard Time',
                    'value': 'UTC+12:00'
                }
            ],
            selectCompanyLogo: "",
            datemodal: false,
            timemodal: false,
            logomodal: false,
            rules: {
                alphanumericSpecCharName: v => {
                    if (v){
                        let alphanumericSpecChar = /^[ A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidName')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'systemsettings',
            'filelist',
            'language',
            'keyboardLang'
        ]),
        langSelection(){
            if (this.systemsettings.language === 'Deutsch'){
                return 'de-DE'
            } else {
                return 'en-GB'
            }
        },
        showLogo(){
            if (typeof this.systemsettings.companyLogo === 'number'){
                return this.filelist[this.systemsettings.companyLogo];
            } else {
                return this.systemsettings.companyLogo;
            }
        },
        timeformatSelection(){
          if (this.systemsettings.timeFormat === '24h'){
            return '24hr'
          } else {
            return 'ampm'
          }
        }
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        deleteLogo(){
            this.$store.commit('deleteLogo');
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                let Systemsettings = {};
                if (this.systemsettings.date != this.originalSystemsettings.date && this.systemsettings.date != "") {
                    Systemsettings.date = this.datePicker;
                }
                if (this.systemsettings.dateFormat != this.originalSystemsettings.dateFormat) {
                    Systemsettings.dateFormat = this.systemsettings.dateFormat;
                }
                if (this.systemsettings.time != this.originalSystemsettings.time && this.systemsettings.time != "") {
                    Systemsettings.time = this.systemsettings.time;
                }
                if (this.systemsettings.timeFormat != this.originalSystemsettings.timeFormat) {
                    Systemsettings.timeFormat = this.systemsettings.timeFormat;
                }
                if (this.systemsettings.timeZone != this.originalSystemsettings.timeZone) {
                    Systemsettings.timeZone = this.systemsettings.timeZone;
                }
                if (this.systemsettings.language != this.originalSystemsettings.language) {
                    Systemsettings.language = this.systemsettings.language;
                    if (this.systemsettings.language === 'Deutsch') {
                        this.$store.commit('setLanguage', 'de');
                        this.$store.commit('setKeyboard', 'euro');
                        this.$i18n.locale = 'de'
                    } else {
                        this.$store.commit('setLanguage', 'en');
                        this.$store.commit('setKeyboard', 'en');
                        this.$i18n.locale = 'en'
                    }
                }
                if (this.systemsettings.companyLogo != this.originalSystemsettings.companyLogo) {
                    if (typeof this.systemsettings.companyLogo === 'number') {
                        Systemsettings.companyLogo = this.filelist[this.systemsettings.companyLogo];
                    } else {
                        Systemsettings.companyLogo = this.systemsettings.companyLogo;
                    }
                }
                if (this.systemsettings.companyName != this.originalSystemsettings.companyName && this.systemsettings.companyName != "") {
                    Systemsettings.companyName = this.systemsettings.companyName;
                }
                if (Object.keys(Systemsettings).length === 0 && Systemsettings.constructor === Object) {
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(()=>{
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['admin/device/systemsettings', Systemsettings])
                    .then(response=>{
                        this.showSaveProgress = false;
                        if (response.status === 200){
                            this.showSaveSuccess = true;
                            this.watcher();
                            setTimeout(()=>{
                                this.saveDisabled = true;
                                this.showSaveSuccess = false;
                            }, 800)
                        }
                    })
                }
            }
        },
        watcher(){
            //copy systemsettings to originalSystemsettings for comparison
            this.originalSystemsettings = JSON.parse(JSON.stringify(this.systemsettings));

            let date = this.$watch('$store.state.systemsettings.date', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let dateFormat = this.$watch('$store.state.systemsettings.dateFormat', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let time = this.$watch('$store.state.systemsettings.time', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let timeFormat = this.$watch('$store.state.systemsettings.timeFormat', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let timeZone = this.$watch('$store.state.systemsettings.timeZone', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let language = this.$watch('$store.state.systemsettings.language', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let companyLogo = this.$watch('$store.state.systemsettings.companyLogo', function() {
                this.saveDisabled = false;
                stopWatch();
            });


            let companyName = this.$watch('$store.state.systemsettings.companyName', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                date();
                dateFormat();
                time();
                timeFormat();
                timeZone();
                language();
                companyLogo();
                companyName();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/device/systemsettings/logolist', 'filelist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/device/systemsettings', 'systemsettings']) // also at Login.vue
            .then(response =>{
                if (response.status === 200){
                    if (this.systemsettings.language === 'Deutsch'){
                        this.$store.commit('setLanguage', 'de');
                        this.$store.commit('setKeyboard', 'euro');
                        this.$i18n.locale = 'de'
                    } else {
                        this.$store.commit('setLanguage', 'en');
                        this.$store.commit('setKeyboard', 'en');
                        this.$i18n.locale = 'en'
                    }

                    //date for Datepicker
                    this.datePicker = mixins.methods.datePickerDate(this.systemsettings.date);
                    this.$watch('datePicker', function(){
                        this.systemsettings.date = mixins.methods.userDateFormat(this.datePicker);
                    })

                    this.watcher();
                }
            });
    },
    watch: {
    }
}
</script>
<style scoped lang="scss">
.SystemSettings{
    position: relative;

    .deleteContainer{
        width: 40px;
        height: 44px;
        margin: -10px -8px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>